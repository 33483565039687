import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Header from '../components/Header';
import DonationsContent from '../components/About/DonationsContent';

export const DonationsPageTemplate = ({
  title,
  blurbs,
  opportunities,
  levels
}) => {
  return (
    <div className="w-full flex flex-col bg-s4tk-white">
      <Header title={title} />
      <DonationsContent
        blurbs={blurbs}
        opportunities={opportunities}
        levels={levels}
      />
    </div>
  );
};

const DonationsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <DonationsPageTemplate
        title={frontmatter.title}
        blurbs={frontmatter.blurbs}
        opportunities={frontmatter.opportunities}
        levels={frontmatter.levels}
      />
    </Layout>
  );
};

export default DonationsPage;

export const donationsPageQuery = graphql`
  query DonationsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "donations-page" } }) {
      frontmatter {
        title
        blurbs {
          text
        }
        opportunities {
          header
          destinations {
            text
          }
        }
        levels {
          title
          description
          icon {
            prefix
            title
          }
          url
        }
      }
    }
  }
`;
