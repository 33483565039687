import React from 'react';
import Section, { SubSection } from '../Section';
import SmallLink from '../SmallLink';
import { VideoBlank } from '../Home/Video';

export const DonationsContent = ({ blurbs, opportunities, levels }) => {
  const colors = [
    'bg-s4tk-blue',
    'bg-s4tk-orange',
    'bg-s4tk-green',
    'bg-red-600',
    'bg-yellow-400',
    'bg-purple-700'
  ];
  return (
    <Section>
      <div className="space-y-4">
        {blurbs.map((item, index) => (
          <p
            key={index}
            className="font-baloo text-s4tk-blue text-3xl text-left sm:text-justify px-0 md:px-4 lg:px-8"
          >
            {item.text}
          </p>
        ))}
      </div>
      <SubSection>
        <p className="font-baloo font-bold text-s4tk-blue text-3xl text-center sm:text-justify px-0 md:px-4 lg:px-8 mb-4 self-center">
          {opportunities.header}
        </p>
        <div className="space-y-4 flex flex-col items-center w-full">
          {opportunities.destinations.map((item, index) => (
            <p
              key={index}
              className="font-baloo text-s4tk-blue text-2xl text-justify px-0 md:px-4 lg:px-8"
            >
              {item.text}
            </p>
          ))}
        </div>
      </SubSection>
      <SubSection>
        <div className="w-full flex flex-row flex-wrap items-center justify-center">
          {levels.map((item, index) => {
            return (
              <div className="w-full md:w-auto" key={index}>
                <SmallLink color={colors[index]} item={item} />
              </div>
            );
          })}
        </div>
      </SubSection>
      <SubSection>
        <VideoBlank
          videoInfo={{ url: 'https://www.youtube.com/embed/AUfOgpWv1io' }}
        />
      </SubSection>
    </Section>
  );
};

export default DonationsContent;
